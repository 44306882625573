import styled from '@emotion/styled'
import { BannerCTA } from 'app/components/BannerCTA'
import { BlockCTA } from 'app/components/BlockCTA'
import { BlockInfos } from 'app/components/BlockInfos'
import { Hero } from 'app/components/Hero'
import { Intro } from 'app/components/Intro'
import { Mosaic } from 'app/components/Mosaic'
import { SEO } from 'app/components/SEO'
import {
  Props as StructuredDataProps,
  StructuredData,
} from 'app/components/StructuredData'
import { ZigZags } from 'app/components/ZigZags'
import { Footer, Props as FooterProps } from 'app/containers/Footer'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { PageProps } from 'gatsby'
import React, { memo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
  footerProps?: FooterProps
  structuredDataProps?: StructuredDataProps
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function HomePageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  return (
    <Container>
      <SEO {...context.seoProps} />
      {context.structuredDataProps ? (
        <StructuredData
          languageCode={pageContext.languageCode}
          {...context.structuredDataProps}
        />
      ) : null}
      {context.headerProps ? (
        <Header
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          pageID={context.id}
          syncroProps={context.syncroProps ? context.syncroProps : null}
          foodButton={true}
          {...context.headerProps}
        />
      ) : null}
      {context.heroProps ? <Hero {...context.heroProps} /> : null}
      {context.introProps ? <Intro {...context.introProps} /> : null}
      {context.zigZagsProps ? <ZigZags {...context.zigZagsProps} /> : null}
      {context.mosaicProps ? <Mosaic {...context.mosaicProps} /> : null}
      {context.blockInfosProps ? (
        <BlockInfos {...context.blockInfosProps} />
      ) : null}
      {context.blockCTAProps ? (
        <BlockCTA variant="food" {...context.blockCTAProps} />
      ) : null}
      {context.bannerCTAProps ? (
        <BannerCTA {...context.bannerCTAProps} />
      ) : null}
      {context.footerProps ? (
        <Footer languageCode={context.languageCode} {...context.footerProps} />
      ) : null}
    </Container>
  )
})

const Container = styled.main``
